.navbar {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
  background: #fff;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1140px;
  margin:auto;
  background: #fff;
}

.navbar-logo {
 /* width: 150px; */
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-bottom: 0.5rem;
}
.nav-menu:last-child li:nth-child(5){padding-right: 0px;}
.nav-menu:last-child li:nth-child(5) a{padding-right: 0px;}
/* .nav-item {
  height: 80px;
} */

.nav-links {
  color: #000;
/* display: inline-block; */
font-size: 16px;
font-weight: 500;
display: flex;
  align-items: center;
  text-decoration: none !important;
  padding: 0.5rem 0.5rem;
  /* border-bottom: 2px solid #fff; */
  /* height: 50%; */
  margin-top: 6px;
}

.nav-links:hover {
  border-bottom: 2px solid #00afe2;
  color:#00afe2;
  /* transition: all 0.2s ease-out; */
}

.fa-bars {
  color: #00afe2;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .topbar-social ul{padding-left:0px;}
  .topbar-social ul li .f-13{font-size: 12px;}
  .features-list::before{left: 36%;}
  .features-list i{margin-left: 44%;}
  .NavbarItems {position: relative;}
  .embed-responsive{height: 242px;}
  .nav-item{background:#0a7493e6;}
  .testy-data {height: 14vh;}
  .testimonial-details{height: 38vh;}
  .border-bg {position: absolute; z-index: -1;transition: all 0.8s;margin-bottom: -2.2rem;margin-right: -1rem; margin-top: 2rem;margin-left: 8%;}
  .dropdown-menu{top: 50% !important;right: 122px;left: auto !important;background-color: #0a7493e6 !important;border: 1px solid #0a7493e6 !important;}
  .dropdown-item{background-color: #006482e6 !important;color: #fff !important;margin-bottom: 5px !important;}
  /* .img-hover{margin-top: 2rem;} */
  .contact-box-inner{margin-top: 3rem;}
  .hidden-xs{display: none;}
  .footer-top{padding: 23px 5px 34px;}
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 47vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
   background: rgba(0,0,0,0.8);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    padding: 0 10px;
  }

  .nav-links {
    color: #fff !important;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 0px;
  }

  .nav-links:hover {
    /* background-color: #fff; */
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 2px;
    left: -15px;
  
  }
  .nav-item{height:50px;}

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #00afe2;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #00afe2;
    padding: 14px 20px;
    border: 1px solid #00afe2;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}
