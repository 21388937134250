
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
body{font-size: 15px !important;color: #757373f7 !important;font-family: 'Poppins', sans-serif !important;font-weight: 400 !important;line-height: 28px !important;overflow-x: hidden;}
.text-center{text-align: center;}
.text-left{text-align: left;}
.text-rigth{text-align: right;}
.pl-5{padding-left: 1rem;}
.pl-6{padding-left: 2rem;}
.pl-7{padding-left: 3rem;}
.pl-8{padding-left: 4rem;}
.pl-9{padding-left: 5rem;}
.pl-10{padding-left: 6rem;}
.pr-5{padding-right: 1rem;}
.pr-6{padding-right: 2rem;}
.pr-7{padding-right: 3rem;}
.pr-8{padding-right: 4rem;}
.pr-9{padding-right: 5rem;}
.pr-10{padding-right: 6rem;}
.pb-5{padding-bottom: 1rem;}
.pb-6{padding-bottom: 2rem;}
.pb-7{padding-bottom: 3rem;}
.pb-8{padding-bottom: 4rem;}
.pb-9{padding-bottom: 5rem;}
.pb-10{padding-bottom: 6rem;}
.pt-5{padding-top: 1rem;}
.pt-6{padding-top: 2rem;}
.pt-7{padding-top: 3rem;}
.pt-8{padding-top: 4rem;}
.pt-9{padding-top: 5rem;}
.pt-10{padding-top: 6rem;}
.ml-5{margin-left: 1rem;}
.ml-6{margin-left: 2rem;}
.ml-7{margin-left: 3rem;}
.ml-8{margin-left: 4rem;}
.ml-9{margin-left: 5rem;}
.ml-10{margin-left: 6rem;}
.mr-5{margin-right: 1rem;}
.mr-6{margin-right: 2rem;}
.mr-7{margin-right: 3rem;}
.mr-8{margin-right: 4rem;}
.mr-9{margin-right: 5rem;}
.mr-10{margin-right: 6rem;}
.mt-4{margin-top: 0.5rem;}
.mt-5{margin-top: 1rem;}
.mt-6{margin-top: 2rem;}
.mt-7{margin-top: 3rem;}
.mt-8{margin-top: 4rem;}
.mt-9{margin-top: 5rem;}
.mt-10{margin-top: 6rem;}
.mb-5{margin-bottom: 1rem;}
.mb-6{margin-bottom: 2rem;}
.mb-7{margin-bottom: 3rem;}
.mb-8{margin-bottom: 4rem;}
.mb-9{margin-bottom: 5rem;}
.mb-10{margin-bottom: 6rem;}
.f-10{font-size: 10px;}
.f-12{font-size: 12px;}
.f-13{font-size: 13px;}
.f-14{font-size: 14px;}
.f-16{font-size: 16px;}
.f-18{font-size: 18px;}
.f-20{font-size: 20px;}
.f-22{font-size: 22px;}
.f-24{font-size: 24px;}
.f-300{font-weight: 300;}
.f-400{font-weight: 400;}
.f-500{font-weight: 500;}
.f-600{font-weight: 600;}
.f-700{font-weight: 700;}
.f-800{font-weight: 800;}
.f-900{font-weight: 900;}
.height1{height:1vh;}
.height2{height:2vh;}
.height3{height:3vh;}
.height4{height:4vh;}
.height5{height:5vh;}
.height6{height:6vh;}
.height7{height:7vh;}
.height8{height:8vh;}
.height9{height:9vh;}
.height10{height:10vh;}
.w-30{width:30%;}
.fl{float: left;}
.fr{float: right;}
.logo{width:12%;}
.current{border-bottom: 2px solid #00afe2;color: #00afe2 !important;}
.testimonial-details i{color:#00afe21a;font-size:50px;}
.quality-section{border-radius: 10px;background:#00afe2;color:#fff;box-shadow:0px,0px,20px, 0px #ccc;margin-top: 4rem;padding: 2rem;box-shadow:0px 10px 14px 0px rgba(23,43,77,0.2)}
.ready-setup {background: #2d3142;padding: 3rem 1rem; margin-top: 3rem;}
.ready-setup h4{text-align: center; color:#fff;font-size: 32px !important;}
.quality-section h4{color:#fff;font-size:32px !important;text-align: center;}

.dlab-topbar-right ul{margin-bottom: 0px;}
.dlab-topbar-right ul li {display: inline-block;padding: 0px 7px;}
.dlab-topbar-right .site-button-link {color: #fff;font-size: 14px;}
.top-bar { border-bottom: 0;background: #00afe2;}
.bg-light1 {background-color: #fff !important;}
.navbar{padding:0rem !important;}
.content h1 {font-size: 36px !important;line-height: 45px !important;margin-bottom: 10px !important;   font-weight: 500;}
.navbar-light .navbar-nav .nav-link {color: #000 !important;display: inline-block;font-size: 16px;font-weight: 500;}
.img-hover{width: 100%;height: auto;overflow: hidden;position: relative;transition: 0.5s ease;z-index: 0;}
.img-hover::after{content: "";position: absolute;width: 80%;height: 0%;left: 10%;right: 10%;top: 50%;bottom: 50%;transition: 0.5s;border-left: 2px solid #fff;border-right: 2px solid #fff;}
.img-hover::before{content: "";position: absolute;width: 0%;height: 80%;left: 50%;right: 50%;top: 10%;bottom: 10%;transition: 0.5s;border-top: 2px solid #fff;border-bottom: 2px solid #fff;}
.img-hover:hover.img-hover::after{content: "";position: absolute;width: 80%;height: 100%;left: 10%;right: 10%;top: 0%;bottom: 0%;z-index: 999;}
.img-hover:hover.img-hover::before{content: "";position: absolute;width: 100%;height: 80%;left: 0%;right: 0%;top: 10%;bottom: 10%;z-index: 999;}
.banner .react-multi-carousel-item{width:fit-content;}
/* .react-multi-carousel-item{height:400px;} */
.section-content{padding-top:3rem;padding-bottom: 3rem;}
.embed-responsive {height: 450px;}
.embed-responsive .embed-responsive-item, .embed-responsive embed, .embed-responsive iframe, .embed-responsive object, .embed-responsive video {height: auto !important;}
.content .text-white{color:#fff;}
.text-white{color:#fff;}
.text-black{color:#000;}
.text-lightblue{color:#00afe2;}
.text-darkblue{color:#022573 !important;}
  .content {position: absolute; bottom: 35%;
    /* background: rgba(0, 0, 0, 0.5);
    color: #f1f1f1 !important;  */
    width: 100%;
    padding: 20px;
    padding-left:50px;
    /* width: 470px; */
    right:12%;
text-align: end;
  }
  .service-title{color:#00afe2;font-size: 24px !important;}
.heading-title{font-size:30px; color:#000;font-weight: 500;text-align: center;}
.ready-setup .btn-white {background: #fff;color: #000;padding: 6px 12px;font-size: 14px;line-height: 1.4585;border-radius: 4px;margin-top:0.2rem;}
/* .heading-title::after{content: '';position: absolute;border-bottom:3px solid #000;left:46.5%;bottom: 0;height: 100%;width:3%;text-align: center;display: flex;}
.heading-title::before{content: '';position: absolute;border-bottom:3px solid #000;right:46.5%;bottom: 0;height: 100%;width:3%;text-align: center;display: flex;} */
.testy-data{height: 60%;}
.product-details{box-shadow: 0px 0px 0px 0px #ccc;padding:10px;border:1px solid #ccc;height:385px;}
.product-details:hover{box-shadow: 0px 2px 2px 0px #ccc;padding:10px;border:1px solid #ccc;transition: 0.5s ease;}
.img-detail-red::before{content: '';position: absolute;left:0px;top:0px;background:rgb(255 73 0 / 44%) !important;}
.img-detail-yellow::before{content: '';position: absolute;left:0px;top:0px;background:rgb(255 168 0 / 42%) !important;}
.img-detail::before{content: '';position: absolute;left:0px;top:0px;background:rgba(0, 123, 255,  0.56)}
.img-detail:hover.img-detail::before {content: "";
  position: absolute;width: 92%;height: 85%;left: 4%;right: 0%;top: 0%;bottom: 10%;z-index: 999;}
.img-detail:hover.img-detail::before .des{opacity: 1;}
.img-content h4 {color: #fff !important;font-size: 18px !important;font-weight: 500 !important;}
.img-content p {opacity: 0;display: none;}
.img-content {position: relative;bottom: 45px;background: #00afe2;color: #f1f1f1;
    width: 100%;padding: 10px;}
.img-content-yellow {position: relative;bottom: 47px;background: #ffb300 !important;color: #f1f1f1;
      width: 100%;padding: 10px;}
.img-content-red {position: relative;bottom: 47px;background: red !important;color: #f1f1f1;
        width: 100%;padding: 10px;}
  .testimonial-details{border-radius: 4px; overflow: hidden;box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.1);background-color: #fff;margin:10px;padding:20px;height: 82%;}
  .dlab-post-meta {border-bottom: 1px solid rgba(0, 0, 0, 0.1);padding-bottom: 12px;
    position: relative;}
  .dlab-post-meta ul {margin: 0;list-style: none;text-transform: capitalize;padding-left: 0px;}
.dlab-post-meta:after {content: ""; width: 50px;height: 2px;background: #00afe2;position: absolute;bottom: -1px;left: 0;}
.m-t-1{margin-top: 1rem;}
.rss-date {color: #00afe2 !important;}
.dlab-post-meta ul li {padding: 0;display: inline-block;color: #00afe2 !important;
  font-weight: 600; font-size: 13px;text-transform: uppercase;}
  .dlab-post-meta ul li a{padding: 0;display: inline-block;color: #00afe2 !important;
    font-weight: 600; font-size: 13px;text-transform: uppercase;text-decoration: none;}
.dlab-post-meta li:after {content: "|";display: inline-block;font-weight: normal;
  margin-left: 5px;opacity: 0.5;}
  .dlab-post-meta li:last-child:after {content: "";}
.chimp{width:82%;}
.nav-item{padding:0px 8px;}
.sub-title{color:#00afe2;font-size: 18px !important;font-weight: 400 !important;}
.sub-title-yellow{color:#ffb300;font-size: 18px !important;font-weight: 400 !important;}
td a{color:#757373f7 !important}
.about-list{list-style-type: square !important;color:#00afe2;display: inline-flex;}
.about-list li{margin-left:40px;}
.sub-title-green{color:#05a505 !important;}
.sub-title-red{color:red;font-size: 18px !important;font-weight: 400 !important;}
.service-box-inner {
  /* width: 85%; */
  width: 100%;
  float: right;margin-top: 0;
  /* padding: 8px 12px 29px 75px; */
  padding: 20px;
  background-color: #fff;box-shadow: 0px 0px 21px rgba(0,0,0,0.3);border: 1px solid #fff;}
.contact-box-inner {margin-top: 0;padding: 8px 20px;background-color: #fff;box-shadow: 0px 0px 21px rgba(0,0,0,0.3);border: 1px solid #fff;}
/* .img-box{border: 3px solid #e3e3e3;border-radius: 100%;top: 50%;position: relative;background-color: #fff;left:90px;} */
.col-md-6 .img-box img {position: absolute;left:10px;background-color: #fff;
  top: 35px;transition: all 0.3s ease;border-radius: 100%;border: 3px solid #e3e3e3;padding:10px;}
  /* .col-md-6 .service-box-inner{height:200px;} */
/* .col-md-4 .service-box-inner{height:200px;} */
/* .col-md-4 .img-box img {position: absolute;left: 0px;background-color: #fff;
  top: 35px;transition: all 0.3s ease;border-radius: 100%;border: 3px solid #e3e3e3;padding:10px;} */
  .col-md-6 .service-box-inner{width: 85%;padding-left: 45px;align-items: center;}
    .col-md-6 .service-box-inner:hover{background: #00afe2;color:#fff;border-color: #00afe2;transition:3s all}  
    .col-md-6 .service-box-inner:hover .sub-title{color:#fff;}
  .col-md-6 .img-box img{  position: absolute;left: -20px;background-color: #fff;top: 3px;transition: all 0.3s ease;border-radius: 100%;border: 3px solid #e3e3e3;padding: 10px;width: 70px;}
  .col-md-4 .img-box i{  position: absolute;left: 20px;background-color: #fff;top: 8px;transition: all 0.3s ease;border-radius: 100%;border: 3px solid #e3e3e3;padding: 10px;width: 70px;text-align: center;color:#00afe2;}
.img-box img {position: absolute;left: 100px;background-color: #fff;
    top: 35px;transition: all 0.3s ease;border-radius: 100%;border: 3px solid #e3e3e3;padding:10px;}
.service-box {padding: 3px 20px;padding-right: 20px;padding-left: 20px;text-align: left;
  display: inline-grid;}
/* .testimonial-details{box-shadow: 0px 0px 0px 0px #ccc;margin:10px;padding:10px;border:1px solid #ccc;}
.testimonial-details:hover{box-shadow: 0px 2px 2px 0px #ccc;padding:10px;border:1px solid #ccc;transition: 0.5s ease;} */
.brands_item {height: auto;box-shadow: 0px 0px 5px 2px #eee;width: auto;
  margin-right: 20px;margin-top:20px;margin-bottom: 20px;margin-left:20px;}
/* .react-multi-carousel-list{padding-left: 15px !important;} */
.underline { height: 3px;width: 10%;background: #00afe2;text-align: center;position: relative;
}
/* .form-control{background-color: #e7f4f9 !important;border-color:#e7f4f9 !important;} */
.form-control:focus{box-shadow: none !important;border-color:#00afe2 !important;background-color: #fff !important;}
.underline::before {content: '';height: 2px;width: 47%;background: #00afe2;text-align: center;
  position: absolute;top: -6px;right: -10px;}
.underline::after { content: '';height: 2px;width: 47%;background: #00afe2;text-align: center;position: absolute;left: -10px;bottom: 6px;}
.brands_item img {display: block;width: 90%;margin: auto;}
.countdown-wrapper{display: flex;}
.title {margin-bottom: 10px;font-size: 35px;line-height: 45px;font-weight: 600;}
.react-multi-carousel-list {display: flex;align-items: center;overflow: hidden;position: initial !important;}
.react-multiple-carousel__arrow--right{right: calc(8% + 1px) !important;}
.react-multiple-carousel__arrow--left{left: calc(7.7% + 1px) !important;}
.countdown-item{width: 25%;text-align: center;background: #fff;box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.07);border-radius: 10px;padding: 30px 30px 23px;transition: 0.3s;margin-right: 20px;}
.read-more-btn {color: #fff !important;font-size: 16px !important;background-color: #00afe2 !important;padding: 10px !important;border-radius: 4px !important;line-height:1 !important;font-weight: 400 !important;}
.test-img{border-radius: 50%;align-items: center;text-align: center;position: relative;top:-2rem;}
.site-footer {background-image: url(../images/footer-bg.jpg);border-top:5px solid #00afe2;}
/* .career-section {background-image: url(../images/background-team.jpg);background-position: bottom;} */
/* .butterfly-section {background-image: url(../images/butterfly.png);} */
.bg-gradient {background-image: linear-gradient(to right, #edf9f5f2 , #d5e4fb94);}
.footer-top {background-size: cover;background-position: center;padding: 50px 0 20px;}
.test-img img{border-radius: 50%;box-shadow: 0px 2px 2px 0px #ccc;}
.container .react-multi-carousel-item{height:auto;}
.icon-color{color:#17a2b8;}
.icon-color1{color:#022573;}
.countdown-item:hover{transform: translateY(-5px);}
.grey-bg{background: #f1f1f1 !important}
.footer-bottom {background-color: #1C1C1C;padding: 0px 0;color: #b0b0b0;font-size: 14px;
  border-top: 1px solid rgba(255, 255, 255, 0.05);}
.section-content .react-multi-carousel-item{width:223px;}
.react-multiple-carousel__arrow:focus{outline: none;}
.m-b15 {margin-bottom: 15px;}
.contact-detail {float: left;width: 100%;padding-top: 10px;}
footer p, footer li {font-size: 13px;line-height: 22px;}
footer{position: relative;bottom: 0px;width: 100%;}
.copyrigth{font-size:12px;padding:5px;color:#fff; background:#000;vertical-align: middle;}
.copyrigth p{margin-bottom: 0rem;}
dl, ol, ul {margin-top: 0; margin-bottom: 1rem;list-style-type: none;}
footer a, footer a:visited {color: #b0b0b0;text-decoration: none;}
/* .list-2 li:before {content: "\e60a";font-family: 'themify';font-size: 11px;left: 0;position: absolute;} */
.icon{width: 20px;display: inline-block;}
.icon1{width: 100px;display: inline-block;}
.contact{padding-left:0px;}
h4 {font-size: 22px !important;line-height: 28px;font-weight: 400 !important;}
.list-2{padding-left: 5px;}
.site-button .sharp {width: 30px; height: 30px;padding: 10px;
  line-height: 31px;background-color: rgba(255, 255, 255, 0.1);}
.list-inline{display: inline-block;}
.list-inline li{display: inline;margin-right: 10px;}
.join-btn{border: 1px solid #00afe2 !important;color: #fff !important; background: #00afe2 !important;border-radius: 0px !important;}
label{color:#000;font-weight: 500;}
.features-list{position: relative;}
.features-list-red::before{border:1px solid #05a505 !important;}
.features-list-yellow::before{border:1px solid #ffb300 !important;}
.features-list::before {float: left;margin-right: 30px;border: 1px solid #00afe2;
  padding: 19px;border-radius: 50%;-moz-border-radius: 50%;-webkit-border-radius: 50%;content: " ";    left: 35%;
  width: 100px;height: 100px;position:absolute;}
  .features-list-yellow i{ color: #ffb300 !important;}
  .features-list-red i{ color: #05a505 !important;}
  .features-list-yellow .ornament {background: #ffb300 !important;}
  .features-list-red .ornament {background: #05a505 !important;}
  .features-list-yellow .ornament::after {border: 1px solid #ffb300 !important;}
  .features-list-red .ornament::after {border: 1px solid #05a505 !important;}
.features-list i{ color: #00afe2;font-size: 48px; margin-left: 43%; margin-top: 25px;}
.features-list .ornament {position: relative; width: 1px;height: 20px;margin-left: auto;
    margin-right: auto;margin-top: 25px;background: #00afe2;}
.features-list .ornament::after { position: absolute;content: "";border: 1px solid #00afe2;border-radius: 50%;
      -moz-border-radius: 50%;-webkit-border-radius: 50%;width: 11px;height: 11px;left: -5px;bottom: -13px;}
.sub-services{background-image:linear-gradient(to right, #ace0e8f2 , #d5e4fb94);color: #fff;
  border-top-right-radius:40%;border-bottom-left-radius:40%;border-bottom:5px solid #022573;margin-bottom:10px;}
  .sub-services p{padding:10px;color:#022573;margin-bottom: 0px;}
  .feature-img{width:30%;}
  .sub-points{padding-left:10px;}
  /* .sub-points::before{content: "\f005";font-family: "Font Awesome 5 Pro";position: absolute;color: #00afe2;} */
      /* services section */
      .main {
        width: 100%;
        min-height: 380px;
        text-align: center;
        position: relative;
        cursor: pointer;
      }
      
      .service {
        background: #fff;
        padding: 30px;
        border-radius: 10px;
        position: absolute;
        bottom: 0;
        z-index: 1;
        box-shadow: 0 0 20px -15px #030380;
        transition: transform .8s;
        height:400px;
      }
      .post_info {
        font-size: 0.933em;
        line-height: 1.2857em;
        margin-bottom: 1.5em;
        margin-top: 1.5em;
    }
    .post_info_date{color:#757373f7;text-decoration: none;}
    .post_info_date:hover{color:#00afe2;text-decoration:none;}
      .service p{height: 110px;}
      /* Mengatur posisi logo service */
      .service-logo {
        width: 130px;
        height: 130px;
        border-radius: 50%;
        margin: -90px auto 0;
        background: #fff;
        border: 2px solid #00afe2;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .service-logo-yellow{border:2px solid #ffb300;}
      .service-logo-red{border:2px solid red;}
      .text-justify{text-align: justify;}
      .service-logo img{ border-radius: 50%;}
      /* Membuat border title */
      .service h4 {
        height: 35px;
        width: 100%;
        margin: 50px auto;
        background: #fff;
        position: relative;
      }
      
      .service h4::after,
      .service h4::before {
        content: '';
        width: 40px;
        height: 30px;
       position: absolute;
       z-index: -1;
      }
      
      .service h4::after {
        background: linear-gradient(to right, #fff, #00afe2);
        right: -3px;
        top: -3px;
      }
      
      .service h4::before {
        background: linear-gradient(to right, #00afe2, #fff);
        left: -3px;
        bottom: -3px;
      }
      
      
      /* membuat shadow untuk service */
      .main::after {
        content: '';
        height: 200px;
        width: 80%;
        background: #fff;
        position: absolute;
        border-radius: 10px;
        bottom: 0;
        left: 10%;
        z-index: -1;
        box-shadow: 0 0 20px -15px #030380;
        transition: transform .8s;
      }
      .main::before {
        content: '';
        height: 200px;
        width: 64%;
        background: #fff;
        position: absolute;
        border-radius: 10px;
        bottom: 0;
        left: 18%;
        z-index: -2;
      }
      
      .main:hover .service {
        transform: translateY(-50px);
      }
      
      .main:hover::after {
        transform: translateY(-25px);
      }
      .service-logo img {
        transition: transform 1s;
      }
      .main:hover .service-logo img {
        transform: rotate(360deg);
      }
      .list-box{padding-left:0px;}
     .list-box>li{box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);line-height: 1.5;border-radius: 4px;color: #fff;margin-bottom: 6px;padding: 10px 20px 10px 5px;-webkit-transition: all 0.5s;display: flex;background: #00afe2;align-items: center;}
  .list-box>li:hover{-webkit-transform: scale(1.05);background: #fff;color:#00afe2;}
  .list-box > li:hover::before{ color: #00afe2;}
  .list-box > li::before {border-radius: 4px 0 0 4px; color: #fff;display: table-cell;height: 100%;padding: 10px 0;text-align: center;top: 0; vertical-align: middle; width: 40px;}
  .list-star li::before { content: "\f005";font-family: "Font Awesome 5 Pro";}
  .customer-yellow::before {border: 50px solid #ffb300 !important;}
  .customer-red::before {border: 50px solid red !important;}
  .customer::before {content: ""; position: absolute;width: 0%;height: 0%;top: 50%;bottom: 50%;right: 50;left: 50%;
    border: 50px solid#00afe2;transition: 1s ease;opacity: 0;}
.customer-yellow:hover.customer-yellow::before {border: 1px solid #ffb300 !important;box-shadow: 0 0 4px 0 #ffb300 !important;}
.customer-red:hover.customer-red::before {border: 1px solid red !important;box-shadow: 0 0 4px 0 red !important;}
.customer:hover.customer::before {
  content: "";position: absolute;width: 100%;height: 100%;top: -4%;bottom: 2%;right: 2%;left: 2%;border: 1px solid #00afe2;opacity: 1;box-shadow: 0 0 4px 0 #00afe2;}
  .list-2 li a{text-decoration: none;}
  .list-2 li a:hover{color:#00afe2;}
  .text-skyblue{color:#022573 !important;}
  .light-font{font-size: 24px;font-weight: 400;color: #6f6d6d;}
  /* .career-bg{background: url(../images/career-back.jpg);background-repeat: no-repeat;background-position: bottom right;background-size: 50%;} */
  .about-bg{background: url(../images/about-background.png);background-repeat: no-repeat;    background-position: 111% 0%;
    background-size: 90%;}
    .mb-0{margin-bottom:0px;}
    .col-md-12 ul{list-style-type: disc !important;}
    footer .form-control{font-size: 12px;}
    footer .btn{padding: 0.17rem .75rem;}
    footer table tr td{line-height: 18px !important;padding:0px 2px;}
    .padding-tb{margin-top: -13px;
      position: absolute;}
    .hidden-md{display: none;}
    .hidden-xs{display: block;}
      .text-right a:hover{color:#b0b0b0;}

@media only screen and (max-width:500px){
 
  /* .react-multi-carousel-track {transform: inherit !important;} */
  .react-multi-carousel-track{transition:none !important;}
  #root{overflow-x: hidden;}
  .hidden-xs{display: none;}
  .hidden-md{display: block;}
  .react-multiple-carousel__arrow--right{right:calc(1% + 1px) !important;}
  .react-multiple-carousel__arrow--left{left:calc(1% + 1px) !important}
  .section-content .react-multi-carousel-item{
    /* width:auto ;margin-left:1.5rem;
    margin-right:1.3rem;padding-left:1.5rem;
    padding-right:1.5rem;align-items: center;
    text-align: center; */
  }
  .about-list{display: block;text-align: left;}
  .navbar{z-index: 9999999 !important;}
  .col-md-6 .img-box img{left: -5px;}
  .col-md-6 .service-box-inner{width: 92%;
    padding-left: 45px;
    align-items: center;
    margin-left: 12px;}
    
  .logo { width: 35%;}
  /* .navbar-collapse{background: rgba(0, 0, 0, 0.7);    transform: translate(0%, 66%) !important;
    position: absolute;width: 100%;z-index: 999;} */
    
  /* .navbar-light .navbar-nav .nav-link {color: #fff !important;display: inline-block;font-size: 16px;font-weight: 500;} */
  form{margin-bottom: 50px;}
  .pl-mobile-5{padding-left: 1rem !important;}
  .feature-img{width:30% !important;}
  .product-details{margin-bottom: 10px;height:auto}
  .img-fluid{width:100%;}
  .content{left:1%;padding:10px !important;bottom: 0%;text-align:center}
  .content h1{font-size: 20px !important;}
  .embed-responsive {
    /* height: 100% !important; */
    height: 118px !important;
  }
  .service p{height:135px;}
  .service{height: 410px;}
  .quality-section h4{font-size: 20px;}
  .features-list{margin-bottom: 2.5rem;}
  form{margin-bottom: 0px;}
  .hidden-xs{display: none;}
  .mobile-mt-1{margin-top:1rem;}
  .mobile-mt-2{margin-top:2rem;}
  .mobile-width-60{width:60%;}
  .quality-section {padding: 1rem;}
  .underline{width: 30%;}
}
.deals-section {
  position: relative;
  padding: 90px 0px 70px;
  background-position: center bottom;
  background-repeat: repeat-x;
}
.seller-section {
  position: relative;
  overflow: hidden;
  padding: 90px 0px 70px; 
  background-position: center top;
   background-repeat: repeat-x;
}
.bg-1{background-image: url(../images/1.png);}
.bg-2{background-image: url(../images/2.png);}
.bg-3{background-image: url(../images/3.png);background-position: top left;background-repeat: no-repeat;background-size: 72%;}
.bg-4{background-image: url(../images/4.png);}
.bg-5{background-image: url(../images/5.png);}
.bg-6{background-image: url(../images/6.jpg);}
a:hover{text-decoration: none !important;}
.border-bg {
  background-color: #E0E5E9;
  -webkit-transition: all 0.8s;
  -moz-transition: all 0.8s;
  -o-transition: all 0.8s;
  transition: all 0.8s;
}
 .border-bg {
  position: absolute;
  z-index: -1;
  -webkit-transition: all 0.8s;
  -moz-transition: all 0.8s;
  -o-transition: all 0.8s;
  transition: all 0.8s;
  margin-bottom: -0.4rem;
  margin-right: -1rem;
  margin-top: -2rem;
  margin-left: 8%;
}
 .border-bg.full-bg {
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
}

 .car-icon {
  position: absolute !important;
  top: auto !important;
  left: 0px !important;
  bottom: 30px !important;
  width: 684px;
  height: 163px;
  background-repeat: no-repeat;
}
.icon-two {
  position: absolute;
  left: 6%;
  top: 22%;
  width: 48px;
  height: 48px;
  border-radius: 50px;
  background-color: #00afe24f;
}
 .icon-one {
  position: absolute;
  left: 10%;
  top: 22%;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background-color: #ffb300;
}
.icon-three {
  position: absolute;
  right: 10%;
  top: 22%;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background-color: #ffb300;
}

 .icon-four {
  position: absolute;
  right: 6%;
  top: 22%;
  width: 48px;
  height: 48px;
  border-radius: 50px;
  background-color: #00afe24f;
}
.icon-five {
  position: absolute;
  right: 4%;
  left: auto;
  top: 42%;
  width: 63px;
  height: 40px;
  background-repeat: no-repeat;
  margin-top: 30%;
}
.icon-five1 {
  position: absolute;
  right: auto;
  left: 4%;
  top: 42%;
  width: 63px;
  height: 40px;
  background-repeat: no-repeat;
  margin-top: 15%;
}
.icon-six {
  position: absolute;
  right: 4%;
  bottom: 20%;
  width: 80px;
  height: 50px;
  background-repeat: no-repeat;
  margin-bottom: -14%;
}
.icon-six1 {
  position: absolute;
  left: 4%;
  bottom: 20%;
  width: 80px;
  height: 50px;
  background-repeat: no-repeat;
  margin-bottom: -30%;
}
 .icons {
  position: absolute;
  left: 0px;
  /* top: 0px; */
  right: 0px;
  bottom: 0px;
}
.icons1 {
  position: relative;
  left: 0px;
  /* top: 0px; */
  right: 0px;
  bottom: 0px;
}
  /* .car-bf{background-image: url(../images/car-icon.png); transform: translate3d(0px, 0px, 0px); transform-style: preserve-3d; backface-visibility: hidden; position: relative; display: block; left: 0px; top: 0px;} */
  .bg-icon-1{background-image: url(../images/icon-2.png);}
  .bg-icon-2{background-image: url(../images/icon-3.png);}
  .bg-icon-3{background-image: url(../images/icon-1.png);}
  .bg-icon-4{background-image: url(../images/icon-4.png);}
  .bg-icon-5{background-image: url(../images/icon-5.png);}
  .bg-icon-6{background-image: url(../images/icon-6.png);}
  .inner-container {
    position: relative;
    padding: 70px 60px 60px;
}
.inner-container:before {
  position: absolute;
  content: '';
  left: -2px;
  top: 0px;
  right: -2px;
  height: 100%;
  transform: skewY(-2deg);
  background-image: -ms-linear-gradient(left, #FC5D62 0%, #fbaa64 100%);
  background-image: -moz-linear-gradient(left, #FC5D62 0%, #fbaa64 100%);
  background-image: -o-linear-gradient(left, #FC5D62 0%, #fbaa64 100%);
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, #FC5D62), color-stop(100, #fbaa64));
  background-image: -webkit-linear-gradient(left, #FC5D62 0%, #fbaa64 100%);
  background-image: linear-gradient(to right, #5ddffc8c 0%, #f7b72894 100%);
}
.inner-container:after {
  position: absolute;
  content: '';
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  clip-path: polygon(0 0, 100% 10%, 100% 100%, 0% 100%);
  box-shadow: 0px 0px 15px rgb(0 0 0 / 10%);
}
 .inner-container .car-icon {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 91px;
  height: 37px;
  z-index: 1;
  -webkit-animation: animateCloud 10s linear infinite;
  -moz-animation: animateCloud 10s linear infinite;
  animation: animateCloud 10s linear infinite;
}
.z-index{z-index:999;}
.p-relative{position: relative;}
.error-page{display: flex;width: 100%;justify-content: center;align-items: center;height:55vh;}
/* .moving-car{background-image:url(../images/car-icon1.png)} */
 .pattern-layer {
  position: absolute;
  left: -280px;
  top: -120px;
  width: 1093px;
  height: 710px;
  z-index: -1;
}
 .pattern-layer-two {
  position: absolute;
  left: 0px;
  bottom: -80px;
  width: 385px;
  height: 200px;
}
.pattern-layer-three {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 385px;
  height: 375px;
  background-repeat: no-repeat;
}
 .pattern-layer-four {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 385px;
  height: 375px;
  background-repeat: no-repeat;}
  .rotate{border-radius: 100%;}

 .icons1 .icon-three-service {
    position: absolute;
    right: 0%;
    top: 100px;
    width: 74px;
    height: 85px;
    background-repeat: no-repeat;
}
.icons1 .icon-one-service {
  position: absolute;
  left: 20%;
  top: 50px;
  width: 14px;
  height: 14px;
  border-radius: 50px;
  border: 2px solid #fd799e;
}
.icons1 .icon-two-service {
  position: absolute;
  left: -8%;
  top: 200px;
  width: 48px;
  height: 48px;
  border-radius: 50px;
  background-color: #fd799e;
}
.icons1 .icon-four-service {
  position: absolute;
  right: 0%;
  top: 200px;
  width: 60px;
  height: 50px;
  background-repeat: no-repeat;
}
.icons1 .icon-five-service {
  position: absolute;
  left: -4%;
  bottom: -500px;
  width: 38px;
  height: 41px;
  background-repeat: no-repeat;
}
.icons1 .icon-six-service {
  position: absolute;
  left: 43%;
  top: 270px;
  width: 60px;
  height: 41px;
  background-repeat: no-repeat;
}
.default-btn {
  font-size: 16px;
  color: #fff;
  background-color: #00afe2;
  padding: 10px 35px;
  line-height: 1;
  -webkit-transition: all .5s;
  transition: all .5s;
  z-index: 1;
  overflow: hidden;
  -webkit-box-shadow: 8px 8px 0 #c5d7f6;
  box-shadow: 8px 8px 0 #c5d7f6;
  --hue: 190;
  text-transform: capitalize;
  border:1px solid #00afe2;
  text-decoration: none;
  border-radius: 4px;
}

.default-btn:hover {
    -webkit-box-shadow: 0 0 0 1em transparent;
    box-shadow: 0 0 0 1em transparent;
    -webkit-animation: pulse 1s;
    animation: pulse 1s;
    background-color: #ffb300;
    color: #fff;
    border:1px solid #ffb300;
    text-decoration: none;
    border-radius: 4px;
}
.default-btn.btn-two {
  -webkit-box-shadow: 8px 8px 0 #8f3d17;
  box-shadow: 8px 8px 0 #caa719b3;
  background-color: #ffb300;
  border:1px solid #ffb300;
  border-radius: 4px;
}
.default-btn.btn-two:hover {
    -webkit-box-shadow: 0 0 0 1em transparent;
    box-shadow: 0 0 0 1em transparent;
    -webkit-animation: pulse 1s;
    animation: pulse 1s;
    background-color: #ffb300;
    color: #fff;
    border-radius: 4px;
}
.page-title:before {
  position: absolute;
  content: '';
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-image: -ms-linear-gradient(top, rgba(255,255,255,0) 0%, #000000 100%);
  background-image: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, #000000 100%);
  background-image: -o-linear-gradient(top, rgba(255,255,255,0) 0%, #000000 100%);
  background-image: -webkit-gradient(linear, left top, left left, color-stop(0, rgba(255,255,255,0)), color-stop(100, #000000));
  background-image: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, #000000 100%);
  background-image: linear-gradient(to left, rgba(255,255,255,0) 0%, #000000 100%);
}
.page-title {
  position: relative;
  padding: 170px 0px;
  text-align: center;
}
/* .contact-bg{background: url('../images/contact1.jpg');} */
.page-title .icons {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 2;
}
.parallax-scene-3{transform: translate3d(0px, 0px, 0px) rotate(0.0001deg);
      transform-style: preserve-3d;
      backface-visibility: hidden;
      pointer-events: none;}
.page-title .icons .icon-one {
  position: absolute !important;
  left: 20% !important;
  top: 32% !important;
  width: 20px;
  height: 40px;
  background-repeat: no-repeat;
}
.parallax-layer{
  background-image: url(../images/icon-7.png);
  transform: translate3d(-2.4px, 4.5px, 0px);
  transform-style: preserve-3d;
  backface-visibility: hidden;
  position: relative;
  display: block;
  background-color:transparent;
  left: 0px;
  top: 0px;
}
.page-title .icons .icon-two {
  position: absolute !important;
  left: auto !important;
  right: 20% !important;
  top: 32% !important;
  width:25px;
  height: 40px;
  background-repeat: no-repeat;
}
.parallax-layer-2{
  background-image: url(../images/icon-8.png);
  transform: translate3d(20.3px, 2.7px, 0px);
  transform-style: preserve-3d;
  backface-visibility: hidden;
  position: absolute;
  display: block;
  background-color:transparent;
  left: 0px;
  top: 0px;
}
.page-title .icons .icon-three {
  position: absolute !important;
  left: auto !important;
  right: 10% !important;
  top: 56% !important;
  width: 22px;
  height: 22px;
  background-repeat: no-repeat;
}
.parallax-layer-3{
  background-image: url(../images/icon-9.png);
  transform: translate3d(38.6px, 6.6px, 0px);
  transform-style: preserve-3d;
  backface-visibility: hidden;
  position: absolute;
  background-color:transparent;
  display: block;
  left: 0px;
  top: 0px;
}
.parallax-layer-4{
  background-image: url(../images/icon-10.png);
  transform: translate3d(23.8px, 4.1px, 0px);
  transform-style: preserve-3d;
  backface-visibility: hidden;
  position: absolute;
  display: block;
  background-color:transparent;
  left: 0px;
  top: 0px;
}
.page-title .icons .icon-four {
  position: absolute !important;
  left: 20% !important;
  top: 86% !important;
  width: 50px;
  height: 55px;
  background-repeat: no-repeat;
}
.parallax-layer-5{
  background-image: url(../images/icon-11.png);
transform: translate3d(5.1px, 1.4px, 0px);
transform-style: preserve-3d;
backface-visibility: hidden;
position: absolute;
background-color:transparent;
display: block;
left: 0px;
top: 0px;
}
.page-title .icons .icon-five {
position: absolute !important;
right: 4% !important;
left: auto !important;
top: -20% !important;
width: 50px;
height: 55px;
background-repeat: no-repeat;
}
.auto-container {
  position: static;
  max-width: 1200px;
  padding: 0px 15px;
  margin: 0 auto;
}
.page-title h2 {
  position: relative;
  color: #ffffff;
  /* font-weight: 700; */
  line-height: 1.3em;
}
.page-title .text {
  position: relative;
  color: #ffffff;
  font-weight: 400;
  line-height: 1.7em;
  font-size: 16px;
  margin-top: 10px;
}
.kenburns-top{animation:kenburns-top 5s ease-out both}
/* ----------------------------------------------
 * Generated by Animista on 2021-4-13 10:13:1
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

 @keyframes kenburns-top{0%{transform:scale(1) translateY(0);transform-origin:50% 16%}100%{transform:scale(1.25) translateY(-15px);transform-origin:top}}